import { channelNames } from '@wix/promote-analytics-adapter'
import { BusinessLogger, Experiments } from '@wix/thunderbolt-symbols'
import { decorateReporter } from './decorateReporter'
import { BiProps } from './types'
import { wixPerformanceMeasurements } from '../wix-perf-measurements'

const PROMOTE_BI_ENDPOINT = 'pa'

const PV_EVENT = {
	SRC: 76,
	EVID: 1109,
}

export const getDefaultChannels = (biProps: BiProps, businessLogger: BusinessLogger, experiments: Experiments) => {
	return [
		{
			name: channelNames.BI_ANALYTICS,
			report: decorateReporter(biProps, channelNames.BI_ANALYTICS, (params: any) => {
				if (params.src === PV_EVENT.SRC && params.evid === PV_EVENT.EVID && params.pn === 1) {
					wixPerformanceMeasurements(experiments)
						.then(({ tti, lcp, cls }) => {
							log({
								...params,
								tti,
								...(typeof lcp !== 'undefined' && { lcp }),
								...(typeof cls !== 'undefined' && { cls }),
							})
						})
						.catch(() => {
							log(params)
						})
				} else {
					log(params)
				}
			}),
		},
	]

	function log(params: object) {
		businessLogger.logger.log(params, { endpoint: PROMOTE_BI_ENDPOINT })
	}
}
